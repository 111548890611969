<template><div><h1 id="making-a-recovery-usb" tabindex="-1"><a class="header-anchor" href="#making-a-recovery-usb"><span>Making a Recovery USB</span></a></h1>
<div class="custom-container danger"><p class="custom-container-title">WARNING</p>
<p>Creating a ChromeOS Recovery USB will <strong>wipe all data</strong> on the USB device.</p>
</div>
<p>In order to reinstall ChromeOS, you need to create a ChromeOS Recovery USB for your specific device.</p>
<p>The easiest way to do so is using the Chromebook Recovery Utility, but if that fails, or you are running Linux, then you will need to manually create the Recovery media.</p>
<h2 id="chromebook-recovery-utility-windows-chromeos-macos" tabindex="-1"><a class="header-anchor" href="#chromebook-recovery-utility-windows-chromeos-macos"><span>Chromebook Recovery Utility (Windows/ChromeOS/macOS)</span></a></h2>
<ol>
<li>
<p>Download the <a href="https://chrome.google.com/webstore/detail/chromebook-recovery-utili/pocpnlppkickgojjlmhdmidojbmbodfm" target="_blank" rel="noopener noreferrer">Chromebook Recovery Utility</a> from the Chrome Web Store.</p>
</li>
<li>
<p>Launch the app, then follow on-screen instructions. If you are unable to find your device, you can select the &quot;Select a model from a list&quot; option.</p>
</li>
<li>
<p>Select the USB drive you want to use.</p>
</li>
<li>
<p>Wait for it to complete.</p>
</li>
</ol>
<h2 id="manually-flash-recovery-image-windows-chromeos-macos-linux" tabindex="-1"><a class="header-anchor" href="#manually-flash-recovery-image-windows-chromeos-macos-linux"><span>Manually Flash Recovery Image (Windows/ChromeOS/macOS/Linux)</span></a></h2>
<ol>
<li>
<p>Go to <a href="https://cros.tech/" target="_blank" rel="noopener noreferrer">cros.tech</a> and find your device.</p>
</li>
<li>
<p>Click on the &quot;Recovery Images&quot; dropdown and select the latest one.</p>
</li>
<li>
<p>Extract the file.</p>
</li>
</ol>
<h3 id="windows" tabindex="-1"><a class="header-anchor" href="#windows"><span>Windows</span></a></h3>
<p>Use <RouteLink to="/docs/reverting/bootableusb.html#flashing-using-rufus-windows">Rufus</RouteLink> to flash the recovery .bin file to USB but make sure to flash in &quot;dd&quot; mode.</p>
<h3 id="macos-chromeos-linux" tabindex="-1"><a class="header-anchor" href="#macos-chromeos-linux"><span>macOS/ChromeOS/Linux</span></a></h3>
<p>Use <RouteLink to="/docs/reverting/bootableusb.html#flashing-with-dd-linux-macos">dd</RouteLink> to flash the recovery .bin file to USB.</p>
</div></template>


